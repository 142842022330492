import React, { useState } from 'react';
import { AppBar, Toolbar, Typography, Container, TextField, Button, Box, CssBaseline } from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#000000', // 黒をメインカラーに設定
    },
    secondary: {
      main: '#ffffff', // 白をセカンダリーカラーに設定
    },
  },
});

const App = () => {
  const [inputText, setInputText] = useState('');
  const [reversedText, setReversedText] = useState('');

  const handleReverseText = () => {
    const sanitizedInput = inputText.replace(/<[^>]*>?/gm, ''); // 入力テキストのサニタイズ
    const reversed = sanitizedInput.split('').reverse().join('');
    setReversedText(reversed);
  };

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ flexGrow: 1 }}>
        <AppBar position="static" color="transparent" elevation={0}>
          <Toolbar>
            <Typography variant="h6" component="div">
              Nyaaan.net|逆さ言葉変換アプリ
            </Typography>
          </Toolbar>
        </AppBar>
      </Box>
      <Container maxWidth="sm" sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', height: '80vh' }}>
        <TextField
          fullWidth
          label="入力テキスト"
          variant="outlined"
          value={inputText}
          onChange={(e) => setInputText(e.target.value)}
        />
        <Box textAlign="center" marginTop="1em">
          <Button
            variant="contained"
            color="primary"
            onClick={handleReverseText}
            style={{
              backgroundColor: '#000000',
              color: '#ffffff',
              padding: '10px 20px',
              borderRadius: '20px',
              fontWeight: 'bold',
              textTransform: 'none',
            }}
          >
            変換
          </Button>
        </Box>
        {reversedText && (
          <Box textAlign="center" marginTop="2em">
            <Typography variant="h6">変換結果:</Typography>
            <Typography variant="body1">{reversedText}</Typography>
          </Box>
        )}
      </Container>
      <Box position="fixed" bottom="0" width="100%" textAlign="center" padding="1em" bgcolor="background.paper">
        <Typography variant="body2" color="textSecondary">
          Powered by Nyaaan.net
        </Typography>
      </Box>
    </ThemeProvider>
  );
};

export default App;
